<template>
  <div class="bg-white">
    <div class="md:px-16">
      <NavHeader />
      <HomeView />
      <MissionView />
      <!-- <ResultsView /> -->
      <ContactView />
      <hr>
      <div class="text-center my-3 text-purple-500">Bubblhub © {{new Date().getFullYear()}}</div>
    </div>
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue'
import HomeView from '@/components/HomeView.vue'
import MissionView from '@/components/MissionView.vue'
// import ResultsView from '@/components/ResultsView.vue'
import ContactView from '@/components/ContactView.vue'

export default {
  components: {
    NavHeader,
    HomeView,
    MissionView,
    // ResultsView,
    ContactView
  }
}
</script>
