<template>
  <div class="flex justify-center mb-20">
    <div class="md:grid md:grid-cols-1 md:gap-10 md:w-2/3 rounded-lg">
      <div class="text-center">
        <div class="mt-2 mb-8 text-4xl font-bold">
          Bel of mail ons
        </div>
        <!-- 
        <div style="height: 600px">
          <div class="lg:h-full bg-contact lg:bg-fixed bg-contain bg-no-repeat bg-center bg-origin rounded-3xl lg:rounded-full">
          </div>
        </div> 
        <img class="md:rounded-full rounded-xl" draggable="false" @mouseover="hover = true" @mouseleave="hover = false" :src="hover ? imgZwaai : imgWerk">
        -->
        <div class="flex justify-between gap-5">
          <div class="flex flex-col w-full rounded-lg bg-gradient-to-r to-blue-200 from-purple-200 text-white">
            <img draggable="false" class="self-center my-5 rounded-full" src="../assets/sacha_pf.jpg" width="200px">
            <!-- <img draggable="false" class="self-center my-5 rounded-full" src="https://media-exp1.licdn.com/dms/image/C5603AQHfGLwmjhlbMA/profile-displayphoto-shrink_800_800/0/1606747655116?e=1628726400&v=beta&t=e8TctqTDO5g5qnbo8bgX0T1P3nox1U4okneLge9mS3g" width="200px"> -->
            <hr>
            <div class="px-3 py-2 bg-black rounded-b-lg bg-gradient-to-r to-blue-300 from-purple-300">
              <div class="text-lg">06-42677837</div>
              <a href="mailto:sacha@bubblhub.com" class="text-lg underline">sacha@bubblhub.com</a>
            </div>
          </div>
          <div class="flex flex-col w-full rounded-lg bg-gradient-to-r to-blue-200 from-purple-200 text-white">
            <!-- <img class="self-center my-5 rounded-full" src="https://media-exp1.licdn.com/dms/image/C4D03AQFBhjDn27rouQ/profile-displayphoto-shrink_800_800/0/1550673006102?e=1628726400&v=beta&t=2lJ1HpreAEZH4vdsyhJby4bAbO6EMRr5lP39G-BiZw8" width="150px"> -->
            <img draggable="false" class="self-center my-5 rounded-full" src="../assets/lucas_pf.jpg" width="200px">
            <hr>
            <div class="px-3 py-2 bg-black rounded-b-lg bg-gradient-to-r to-blue-300 from-purple-300">
              <div class="text-lg">06-23235362</div>
              <a href="mailto:lucas@bubblhub.com" class="text-lg underline">lucas@bubblhub.com</a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="bg-local rounded-lg" style="background-position: right; background-repeat: no-repeat; background-image: linear-gradient(rgba(135, 80, 156, 0.0), rgba(135, 150, 156, 0.5)), url(https://www.channelfutures.com/files/2019/08/People-shaking-hands-in-office-877x432.png);">
      </div> -->
    </div>
  </div>  
</template>

<script>
export default {
  data(){
    return{
      hover: false,
      imgWerk: require('../assets/kantoor-werk.jpeg'),
      imgZwaai: require('../assets/kantoor-zwaai.jpeg')
    }
  }
}
</script>

<style scoped>
  .bg-contact {
    background-image: url("~@/assets/kantoor-werk.jpeg");
    /* height: 600px; */
    width: 120%;
    margin-left: -10%;
  }
  .bg-contact:hover {
    background-image: url("~@/assets/kantoor-zwaai.jpeg");
  }
</style>