<template>
  <div class="flex justify-center mb-20">
    <div class="md:flex text-center md:text-left md:justify-between xl:w-2/3 mt-20">
      <div class="xl:-ml-20 xl:mr-10 flex xl:justify-start justify-center">
        <div class="circle-2 text-white bg-gradient-to-r to-blue-100 from-green-100 rounded-full flex items-center justify-center">
          <div class="circle-1 text-white bg-gradient-to-r to-blue-200 from-green-200 rounded-full flex items-center justify-center">
            <img src="@/assets/bubble-screen.png" class="-mr-20 z-10 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" width="300px" draggable="false">
            <img src="@/assets/chat-screen.png" id="chat" class="-ml-10 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-125" width="250px" draggable="false">
          </div>
        </div>
      </div>
      <div class="mt-6 ml-10 pr-6">
        <p class="text-lg font-bold">Wat doet Bubblhub</p>
        <p>
          De mogelijkheden om te verbinden zijn eindeloos! Met onze tool en interventies kan je als organisatie bepalen waarover jij wil verbinden met iedereen en wat je daarmee wil gaan bereiken. Als je dat eenmaal weet kunnen we onderzoeken waar de gemeenschappelijke factoren zitten in jouw organisatie. Vanuit deze gemeenschappelijkheid kunnen we stappen zetten richting een sterker verbonden organisatie.
        </p>
        <p class="mt-4 text-lg font-bold">Onze missie</p>
        <p class="mb-6">
          In een wereld waarin digitaal (thuis) werken steeds verder gaat, ondersteund Bubblhub mensen in organisaties bij het versterken van hun sociale interactie. Wij faciliteren ontmoetingen die bestaande verbindingen verdiepen en nieuwe creëren. Onze digitale en face to face interventies helpen organisaties een gezond sociaal klimaat te realiseren.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    scrollToBottom(){
      window.scrollTo(0,document.body.scrollHeight);
    }
  }
}
</script>

<style scoped>
.circle-1{
  height: 350px;
  width: 350px;
}
.circle-2{
  height: 450px;
  width: 450px;
}

#chat:hover{
  z-index: 30;
}
</style>
