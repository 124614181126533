<template>
  <div class="flex justify-center">
    <div class="grid grid-cols-1 justify-items-center md:flex justify-center xl:w-2/3 mt-20">
      <div class="md:mt-20 md:mb-0 mb-10 md:w-3/5 md:text-left text-center pr-6">
        <p class="text-5xl font-bold">
          Hoe sociaal <br>verbonden zijn jullie?
        </p>
        <p class="mt-4 mb-6 text-lg">
          Wij geloven dat een sterk verbonden groep mensen het verschil kan maken in een steeds sneller veranderende wereld.
        </p>
        <a href="https://bubblhub.nl/registreren" class="p-3 lg:px-4 text-white text-center rounded bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 transition-colors duration-300">Hoe wij dat doen</a>
        <a @click="scrollToBottom()" class="cursor-pointer p-3 lg:px-4 mx-2 text-indigo-600 text-center border border-transparent rounded hover:bg-indigo-100 hover:text-indigo-700 transition-colors duration-300">Gesprek inplannen</a>
      </div>
      <a href="https://www.bubblhub.nl/login">
        <div class="circle-3 text-white bg-gradient-to-r to-blue-100 from-purple-100 rounded-full flex items-center justify-center">
          <div class="circle-2 text-white bg-gradient-to-r to-blue-300 from-purple-500 rounded-full flex items-center justify-center">
            <div class="circle-1 text-white bg-gradient-to-r to-blue-400 from-purple-600 rounded-full flex items-center justify-center">
              <img src="@/assets/bubblhub-symbol.png" width="100px" draggable="false">
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    scrollToBottom(){
      window.scrollTo(0,document.body.scrollHeight);
    }
  }
}
</script>

<style scoped>
.circle-1{
  height: 200px;
  width: 200px;
}
.circle-2{
  height: 250px;
  width: 250px;
}
.circle-3{
  height: 350px;
  width: 350px;
}
</style>
